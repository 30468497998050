<template>
  <v-form ref="form" v-model="valid">
    <v-container style="justify-content: center; display: flex">
      <v-col style="max-width: 500px">
        <v-col>
          <p>Ingrese Sponsor</p>
            <v-select
            v-model="userData.sponsor"
            :rules="sponsorRules" 
            :items="items"
            label="Seleccione"
            solo
          ></v-select>
        </v-col>

    

  

        <v-btn color="primary" @click="validate()"> Siguiente </v-btn>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>
import ShieldService from "../services/shield.service";

export default {
  name: "InitForm",
  props: ["userData"],
  data: () => ({
    items: [],
    valid: false,
    sponsorRules: [(v) => !!v || "Ingrese un sponsor Valido"],
    nameRules: [(v) => !!v || "El nombre es obligatorio"],
    emailRules: [
      (v) => !!v || "El correo electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es obligatorio",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
  }),
  mounted() {
    this.getSponsor()
  },
  methods: {
    async getSponsor(){
      const res = await ShieldService.getSponsors();
      this.items = res.sponsors;
    },

    async getPlans(){
      console.log(this.userData.sponsor)
      const res = await ShieldService.getPlans(this.userData.sponsor);
      console.log(res)
    },

    validate() {
      if (this.$refs.form.validate()) {
        console.log(this.sponsor)
        this.$emit("next-step");
      }
    },
    async change() {

      var values;
      if(this.version == null){
        values = [
          this.userData.name,
          this.userData.email,
          this.userData.phoneNumber
        ]
        this.version = 1
      }else if (this.version != null){
        values = [
          this.userData.name,
          this.userData.email,
          this.userData.phoneNumber
        ]
      }    

      try {
          var carro = await ShieldService.createStep(values, 1, this.version, this.userData.stepId)
          this.userData.stepId = carro.data.data._id
          this.version = 2
        } catch (error) {
          console.log("Cart fail");
        }
    },
  },
};
</script>