<template>
  
    <v-container style="justify-content: center; display: flex; flex-direction: column">
      <div class="form-container">
        <v-col style="max-width: 450px">
          <h1>Tus datos - Seguro Oncologico</h1>
          <v-col>
            <v-text-field @change="change()" v-model="userData.name" :rules="nameRules" label="Nombre" required dense>
              <v-icon slot="prepend"> mdi-account </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field @change="change()" v-model="userData.surname" :rules="surnameRules" label="Apellido" required
              dense>
              <v-icon slot="prepend"> mdi-account-check </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field @change="change()" v-model.lazy="newRut" :rules="rutRules" label="RUT" required dense
              hide-details="auto" @input="changeRut">
              <v-icon slot="prepend"> mdi-key </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field @change="change()" v-model="userData.serialNumber" :rules="serialNumberRules"
              label="N de serie" required dense>
              <v-icon slot="prepend"> mdi-numeric </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-select @change="change()" :items="regions" v-model="userData.region" :rules="regionRules"
              :loading="loadingRegions" v-on:change="getCommunes()" item-text="name" label="Region" required dense
              return-object>
              <v-icon slot="prepend"> mdi-map-marker </v-icon>
            </v-select>
          </v-col>

          <v-col>
            <v-select @change="change()" :items="communes" v-model="userData.commune" :rules="communeRules"
              :loading="loadingCommunes" :disabled="loadingCommunes || userData.region === null" item-text="name"
              label="Comuna" required dense return-object>
              <v-icon slot="prepend"> mdi-select-place </v-icon>
            </v-select>
          </v-col>

          <v-col>
            <v-text-field @change="change()" v-model="userData.address" :rules="addressRules" label="Dirección" required
              dense>
              <v-icon slot="prepend">
                mdi-map-marker-outline
              </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field @change="change()" v-model="userData.email" :rules="emailRules" label="E-mail" required dense>
              <v-icon slot="prepend"> mdi-at </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field @change="change()" v-model="userData.phoneNumber" :rules="phoneRules" :counter="9"
              prefix="+56" label="Telefono" required dense>
              <v-icon slot="prepend"> mdi-phone </v-icon>
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model="userData.nameAgreement" :rules="nameAgreementRules" label="Nombre empresa convenio"
              required dense>
              <v-icon slot="prepend"> mdi-format-align-justify </v-icon>
            </v-text-field>
          </v-col>
        </v-col>

        <v-col style="max-width: 450px">
          <h1>Asegurados</h1>
          <v-col>
            <v-btn color="primary">Agregar Asegurado</v-btn>
          </v-col>


         

          <!-- <v-col>
            <v-checkbox @change="change()" v-model="petData.hasMicrochip" :rules="microchipRules"
              label="¿Tiene microchip?" required dense>
              <v-icon slot="prepend"> mdi-chip </v-icon>
            </v-checkbox>
          </v-col> -->

          <v-col>

            <v-checkbox :rules="conditionsRules" required dense>
              <v-icon slot="prepend"> mdi-file-document </v-icon>
              <template v-slot:label>
                Acepto Términos y Condiciones&thinsp;
              </template>
            </v-checkbox>
          </v-col>

          <v-col>

            <v-checkbox :rules="exclusionRules" required dense>
              <v-icon slot="prepend"> mdi-file-document-multiple</v-icon>
              <template v-slot:label>
                Acepto las coberturas y exclusiones que se me han informado&thinsp;
              </template>
            </v-checkbox>
          </v-col>

        </v-col>
      </div>
      <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn color="primary" @click="validate()"> Confirmar y pagar </v-btn>
        <v-btn text @click="back()"> Atras </v-btn>
      </v-row>
    </v-container>
</template>

<script>
import catIcon from "@/assets/images/cat.png";
import dogIcon from "@/assets/images/dog.png";
import RutUtils from "../utils/rut.utils";
import BreedsService from "../services/breeds.service";
import CountryService from "../services/country.service";
import AgeService from "../services/age.service";
import ShieldService from "../services/shield.service";

export default {
  name: "DetailForm",
  props: ["userData", "petData"],
  data: () => ({
    valid: false,
    sexOptions: [
      {
        id: "male",
        label: "Macho",
      },
      {
        id: "female",
        label: "Hembra",
      },
    ],
    nameRules: [(v) => !!v || "El nombre es obligatorio"],
    nameAgreementRules: [(v) => !!v || "El nombre de empresa convenio es obligatorio"],
    surnameRules: [(v) => !!v || "El apellido es obligatorio"],
    rutRules: [
      (v) => !!v || "El RUT es obligatorio",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    serialNumberRules: [
      (v) => !!v || "El numero de serie es obligatorio",
      (v) => (!!v && v.length > 8) || "Ingrese número de serie correctamente",
    ],
    regionRules: [
      (v) => !!v || "Ingresa la region en la que vives con tu mascota",
    ],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives"],
    addressRules: [(v) => !!v || "Debe ingresar su dirección"],
    emailRules: [
      (v) => !!v || "El correo electrónico es obligatorio",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es obligatorio",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    breedRules: [(v) => !!v || "Necesitamos saber la raza de tu mascota"],
    ageRules: [(v) => !!v || "Debes indicar la edad de tu mascota"],
    sexRules: [(v) => !!v || "Selecciona el sexo de tu mascota"],
    microchipRules: [
      (v) => !!v || "Tu mascota debe tener microchip para contratar el seguro",
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
    exclusionRules: [
      (v) => !!v || "Debe aceptar las coberturas y exclusiones",
    ],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    newRut: "",
    selectedSpecies: null,
    breeds: [],
    speciesOptions: [
      {
        id: "cat",
        name: "Gato",
        image: catIcon,
      },
      {
        id: "dog",
        name: "Perro",
        image: dogIcon,
      },
    ],

    regions: [],
    communes: [],
    ages: [],
    loadingRegions: false,
    loadingCommunes: false,
    loadingAges: false,
  }),
  mounted() {
    this.getRegions();
    this.getAges();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("next-step");
      }
    },
    message() {
      this.$alert("El chip para tu mascota y posterior registro en www.registratumascota.cl, es un requisito para la ley N°21020 de Tenencia Responsable o Ley Cholito. No olvides realizar este proceso.", "Información", "info")
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    async getBreeds() {
      if (this.petData.species) {
        this.petData.breed = null;
        this.breeds = await BreedsService.getBreeds(this.petData.species.id);
      }
    },
    async getAges() {
      this.loadingAges = true;
      this.ages = await AgeService.getAges();
      this.loadingAges = false;
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    async change() {

      var values;

      try {
        values = [
          this.userData.name,
          this.userData.surname,
          this.userData.serialNumber,
          this.userData.rut,
          this.userData.region.name,
          this.userData.commune.name,
          this.userData.address,
          this.userData.email,
          this.userData.phoneNumber,
          this.petData.species.name,
          this.petData.breed,
          this.petData.name,
          this.petData.age.label,
          this.petData.sex.label,
          this.petData.hasMicrochip,
        ]
        var carro = await ShieldService.createStep(values, 3, 0, this.userData.stepId)
        this.userData.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart fail");
      }
    },
  },
};
</script>
<style lang="scss">
.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>