<template>
  <div class="plan">

    <h4 class="plan-name">{{ planData.name }}</h4>
    <v-row style="flex: none;">
      <div class="priceCLP">{{ formatPrice(planData.priceCLP) }}</div>
      <v-col class="px-1">
        <div>CLP /</div>
        <div>MES</div>
      </v-col>
    </v-row>
    <v-row style="flex: none;">
      <div>UF {{ ufchange(planData.price) }}</div>
    </v-row>
    <v-col style="display: flex; flex-direction: column; align-items: center;">
      <h3>¿Qué cubre?</h3>
      <div class="cover" v-for="cover in planData.coverage" :key="cover._id">
        <div class="desc">{{cover.description}}</div>
        <div class="amount" v-if="cover.amount && (cover.type == 'CLP')">{{ formatPrice(cover.amount)}} {{cover.type}}
        </div>
        <div class="amount" v-if="cover.amount && (cover.type == 'UF')">{{ cover.amount}} {{cover.type}}</div>
      </div>
    </v-col>
    <div style="height: 1rem;"></div>
    <v-btn color="primary" @click="validate()"> ¡Lo quiero! </v-btn>
  </div>
</template>

<script>
export default {
  name: "Plan",
  props: ["planData"],
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
  }),
  methods: {
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf){
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    validate() {
      //console.log(this.planData)
      this.$emit("next-step", this.planData);
    },
    parseNumber(n) {
      return new Intl.NumberFormat().format(n);
    },
  },
};
</script>

<style lang="scss" scoped>





.plan {
  background-color: #ffffff;
  border: 2px solid #032D76;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  height: fit-content;

  h4 {
    &.plan-name {
      text-transform: uppercase;
      color: #032D76;
      letter-spacing: 0.3rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .priceCLP {
      color: #032D76;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;
  }

  .cover {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #032D76;
      align-items: center;
      justify-content: center;

      .desc {
          text-align: center;
      }

      .amount {
          color: #032D76;
          font-weight: 600;
      }
  }

  .cover:last-child {
      border-bottom: none;
  }
}
</style>