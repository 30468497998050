<template>
  <div v-if="this.userData.type == 'pet'">
    <Pet 
      :userData="userData"
      :petData="petData"
      @next-step="nextStep(3)"
      @back="back(3)"/>
  </div>
  <v-form ref="form" v-model="valid" v-else-if="this.userData.type == 'oncologico'">
    <Onco 
      :userData="userData"
      :petData="petData"
      @next-step="nextStep(3)"
      @back="back(3)"
    />
  </v-form>
  <v-form ref="form" v-model="valid" v-else-if="this.userData.type == 'pack'">
    <Pack 
      :userData="userData"
      :petData="petData"
      @next-step="nextStep(3)"
      @back="back(3)"
    />
  </v-form>
</template>

<script>
import catIcon from "@/assets/images/cat.png";
import dogIcon from "@/assets/images/dog.png";
import RutUtils from "../utils/rut.utils";
import BreedsService from "../services/breeds.service";
import CountryService from "../services/country.service";
import AgeService from "../services/age.service";
import ShieldService from "../services/shield.service";
import Pet from "@/components/GianniMorandi-pet.vue";
import Onco from "@/components/Emmanuel-oncologico.vue"
import Pack from "@/components/Ronaldinho-package.vue"


export default {
  components: { Pet, Onco, Pack },
  name: "DetailForm",
  props: ["userData", "petData"],
  data: () => ({
    valid: false,
    sexOptions: [
      {
        id: "male",
        label: "Macho",
      },
      {
        id: "female",
        label: "Hembra",
      },
    ],
    nameRules: [(v) => !!v || "El nombre es obligatorio"],
    nameAgreementRules: [(v) => !!v || "El nombre de empresa convenio es obligatorio"],
    surnameRules: [(v) => !!v || "El apellido es obligatorio"],
    rutRules: [
      (v) => !!v || "El RUT es obligatorio",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    serialNumberRules: [
      (v) => !!v || "El numero de serie es obligatorio",
      (v) => (!!v && v.length > 8) || "Ingrese número de serie correctamente",
    ],
    regionRules: [
      (v) => !!v || "Ingresa la region en la que vives con tu mascota",
    ],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives"],
    addressRules: [(v) => !!v || "Debe ingresar su dirección"],
    emailRules: [
      (v) => !!v || "El correo electrónico es obligatorio",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es obligatorio",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    breedRules: [(v) => !!v || "Necesitamos saber la raza de tu mascota"],
    ageRules: [(v) => !!v || "Debes indicar la edad de tu mascota"],
    sexRules: [(v) => !!v || "Selecciona el sexo de tu mascota"],
    microchipRules: [
      (v) => !!v || "Tu mascota debe tener microchip para contratar el seguro",
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
    exclusionRules: [
      (v) => !!v || "Debe aceptar las coberturas y exclusiones",
    ],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    newRut: "",
    selectedSpecies: null,
    breeds: [],
    speciesOptions: [
      {
        id: "cat",
        name: "Gato",
        image: catIcon,
      },
      {
        id: "dog",
        name: "Perro",
        image: dogIcon,
      },
    ],

    regions: [],
    communes: [],
    ages: [],
    loadingRegions: false,
    loadingCommunes: false,
    loadingAges: false,
  }),
  mounted() {
    this.getRegions();
    this.getAges();
  },
  methods: {
    nextStep() {
      this.$emit("next-step");
    },
    back() {
      this.$emit("back");
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("next-step");
      }
    },
    message() {
      this.$alert("El chip para tu mascota y posterior registro en www.registratumascota.cl, es un requisito para la ley N°21020 de Tenencia Responsable o Ley Cholito. No olvides realizar este proceso.", "Información", "info")
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    async getBreeds() {
      if (this.petData.species) {
        this.petData.breed = null;
        this.breeds = await BreedsService.getBreeds(this.petData.species.id);
      }
    },
    async getAges() {
      this.loadingAges = true;
      this.ages = await AgeService.getAges();
      this.loadingAges = false;
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    async change() {

      var values;

      try {
        values = [
          this.userData.name,
          this.userData.surname,
          this.userData.serialNumber,
          this.userData.rut,
          this.userData.region.name,
          this.userData.commune.name,
          this.userData.address,
          this.userData.email,
          this.userData.phoneNumber,
          this.petData.species.name,
          this.petData.breed,
          this.petData.name,
          this.petData.age.label,
          this.petData.sex.label,
          this.petData.hasMicrochip,
        ]
        var carro = await ShieldService.createStep(values, 3, 0, this.userData.stepId)
        this.userData.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart fail");
      }
    },
  },
};
</script>
<style lang="scss">
.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>