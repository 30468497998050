<template>

  <v-container style="justify-content: center; display: flex">
  
    <v-col>
      <div class="plan-container">
        <Plan v-for="plan in plans" :key="plan._id" :planData="plan" @next-step="validate" />
      </div>
      <v-row style="justify-content: center; margin-top: 1rem">
        <!-- <v-btn color="primary" @click="validate()"> Siguiente </v-btn> -->
        <v-btn text @click="back()"> Atras </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Plan from "@/components/Plan.vue";
//import ShieldService from "../services/shield.service";

export default {
  name: "PlanForm",
  props: ["planData","userData","plans"],
  components: { Plan },
  data: () => ({

    loadingPlans: true,
  }),
  mounted() {
    //this.getPlans();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    validate() {
      this.$emit("next-step", 2);
    },
    
  },
};
</script>


<style lang="scss" scoped>
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
</style>